import { policyList } from "@/enums/permissions";
import { DoctorTable } from "@/interfaces/doctor";
import { DoctorTableMetadata } from "@/metadata/doctors";
import { doctorActionService } from "@/services/doctor.actions.service";
import { doctorService } from "@/services/doctor.service";
import { BooleanCheck, PageNavAction } from "@/types/types";
import {
  ActionSubheader,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./Doctors.template.vue";
import { DoctorFiltersComponent } from "./DoctorsFilters/DoctorsFilters.component";

@Component({
  mixins: [Template],
  components: {
    TableComponent,
    DoctorFiltersComponent
  },
  inject: ["$changes"]
})
// tslint:disable: no-string-literal
export default class DoctorsComponent extends Vue {
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;

  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  /**
   * Flag to determine if a filter was applied on last search.
   */
  public activeFilter: boolean = true;

  /**
   * Enable filter view for qualified/non qualified doctors.
   */
  public showFilters: boolean = false;

  /**
   * Data to display in the table.
   */
  public doctors: DoctorTable[] = [];

  /**
   * Pagination data.
   */
  public pagination: TablePagination = doctorService.getResetedPagination();

  /**
   * Loading state.
   */
  public loading: boolean = true;

  /**
   * Headers data for the table
   */
  public headers: TableHeader[] = DoctorTableMetadata;

  /**
   * Selected column to sort list
   */
  public columnToSortBy: string = "updated_at";
  public sortDescending: boolean = true;

  /**
   * Filter by which doctor will be fetched
   */
  public filterToPass: string = "ACTIVE";

  /**
   * term to search with for doctors
   */
  public searchTerm: string = "";

  /**
   * General actions for subheader component.
   */
  public generalActions: ActionSubheader[] = doctorActionService.getDoctorGeneralActions(
    this.filtersCallback
  );

  /**
   * Row actions data for the table.
   */
  public rowActions: TableAction[] = doctorActionService.getDoctorsRowActions(
    this.triggerSearch
  );

  /**
   * Table colors.
   */
  protected colors = ["white", "white", "white"];

  /**
   * Multi actions options.
   */
  protected multiActions: TableAction[] = doctorActionService.getMultiActions(
    this.triggerSearch
  );

  public setFilterStatus(newValue: boolean) {
    this.activeFilter = newValue;
  }

  /**
   * Opens filters qualified/non qualified filters.
   */
  public filtersCallback() {
    this.showFilters = !this.showFilters;
  }

  /**
   * Updates requests on table page change event.
   * @param pagination: TablePagination
   */
  public onPageChange(pagination: TablePagination) {
    this.pagination = pagination;
    this.triggerSearch();
  }

  /**
   * Updates requests on column name click to sort.
   * @param header: TableHader
   */
  public onSort(header: TableHeader) {
    this.columnToSortBy = header.value;
    this.sortDescending = !!header.descending;
    this.triggerSearch();
  }

  /**
   * Triggers search for qualified/non qualified doctors.
   * @param header: TableHader
   */
  public onFilter(filter: { active: boolean }) {
    this.filterToPass = filter.active ? "ACTIVE" : "INACTIVE";
    this.pagination = doctorService.getResetedPagination();
    this.triggerSearch();
  }

  /**
   * Updates table data based on search component.
   * @param term: string
   */
  public async onSearch(searchTerm: string) {
    this.searchTerm = searchTerm;
    this.pagination = doctorService.getResetedPagination();
    this.triggerSearch();
  }
  public async onCloseSearchPanel() {
    this.searchTerm = "";
    this.pagination = doctorService.getResetedPagination();
    this.triggerSearch();
  }

  public getEnableDisableDoctorPermission() {
    return this.hasPermission(policyList.enableDisableDoctors);
  }

  private async mounted() {
    this.setPageNav({
      title: "doctors.title",
      rightActions: {
        generalActions: () => this.generalActions,
        onSearch: this.onSearch,
        onClose: this.onCloseSearchPanel
      }
    });
    this.triggerSearch();
  }

  private async triggerSearch() {
    this.loading = true;
    this.doctors = await doctorService.getDoctors(
      this.pagination.currentPage,
      this.pagination.itemsPerPage,
      this.filterToPass,
      this.searchTerm,
      this.columnToSortBy,
      this.sortDescending
    );
    this.pagination = await doctorService.getPagination();
    this.loading = false;
  }

  private beforeDestroy() {
    // @ts-ignore
    this.$barcodeScanner.destroy();
  }
}
