import { i18n } from "@/plugins/i18n";
import { TableFieldType, TableHeader } from "helix-vue-components";

export const DoctorTableMetadata: TableHeader[] = [
  {
    value: "first_name",
    label: i18n.t("doctors.first_name").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    value: "middle_name",
    label: i18n.t("doctors.middle_name").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    value: "last_name",
    label: i18n.t("doctors.last_name").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    value: "title",
    label: i18n.t("doctors.title_field").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong"
  },
  {
    value: "national_provider_identifier",
    label: i18n.t("doctors.national_provider_identifier").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong"
  }
];
