import debounce from "lodash/debounce";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./DoctorsFilters.template.vue";

@Component({
  mixins: [Template]
})
export class DoctorFiltersComponent extends Vue {
  public loaded: boolean = true;

  @Prop({ default: () => false })
  public isActive!: boolean;

  protected dFilter = debounce(
    c =>
      c.$emit("filter", {
        active: c.isActive
      }),
    500
  );

  public toggleActive() {
    this.$emit("setFilter", !this.isActive);
    this.filter();
  }

  public filter() {
    this.dFilter(this);
  }
}
