import { policyList } from "@/enums/permissions";
import { EventBus, store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { doctorService } from "@/services/doctor.service";
import {
  ActionSubheader,
  TableAction,
  TableSuccessModalResponse
} from "helix-vue-components";

class DoctorActionService {
  public getDoctorsRowActions(onSuccess: () => void): TableAction[] {
    return [
      {
        icon: "fal fa-pen",
        id: "btn_edit_prod",
        action: doc => {
          store.dispatch("RouterModule/go", {
            name: "doctors-edit",
            params: {
              id: doc.id
            }
          });
        }
      },
      {
        icon: "",
        switch: "is_active",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableDoctors
          ),
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n.t("doctor_status_change_message").toString(),
          modalSuccessText: "Yes",
          modalSuccessAction: async (arg: TableSuccessModalResponse) => {
            try {
              await doctorService.toggleDoctorState(
                arg!.modelValue ? "enable" : "disable",
                arg!.item!.id
              );
              arg.unselectModal();
              onSuccess();
            } catch (e) {
              EventBus.$emit("unSelectedModalActive");
              arg.unselectModal();
            }
          },
          modalCancelText: "No"
        }
      }
    ];
  }

  public getDoctorGeneralActions(
    filtersCallback: () => void
  ): ActionSubheader[] {
    return [
      {
        icon: "fal fa-plus",
        id: "btn_create_prod",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.createDoctors
          ),
        action: () => {
          store.dispatch("RouterModule/go", {
            name: "doctors-add"
          });
        }
      },
      {
        icon: "fal fa-filter",
        id: "btn_filter_prod",
        action: filtersCallback
      },
      {
        icon: "fal fa-search",
        id: "btn_search_prod",
        otherComponent: {
          name: "TableSearchComponent"
        }
      }
    ];
  }

  public getMultiActions(onSuccess: () => void): TableAction[] {
    return [
      {
        icon: "fa-toggle-off",
        async action(doctors: []) {
          const payload = doctors.map((i: { id: number } | null) => i!.id);
          await doctorService.toggleDoctorStateMultiple("disable", payload);
          onSuccess();
        }
      },
      {
        icon: "fa-toggle-on",
        async action(doctors: []) {
          const payload = doctors.map((i: { id: number } | null) => i!.id);
          await doctorService.toggleDoctorStateMultiple("enable", payload);
          onSuccess();
        }
      }
    ];
  }
}

export const doctorActionService = new DoctorActionService();
